import { render, staticRenderFns } from "./OrganizationProfileEdit.vue?vue&type=template&id=d66d9bf2&"
import script from "./OrganizationProfileEdit.vue?vue&type=script&lang=js&"
export * from "./OrganizationProfileEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports